<template>
<div class="ui-pc">
    <div class="ui-pc__content">
        <header class="ui-pc__header">
            <span class="ui-pc__close-btn" @click="$emit('closePCFormEvent')">&times;</span>
            <span class="ui-pc__status">
                <img class="ui-pc__status-icon" :src="require(`@/assets/images/eligble-icon.png`)" />
            </span>
        </header>

        <div class="ui-pc__body">
            <p class="ui-pc__title">{{ $t("pc_heading") }}</p>
            <p class="ui-pc__intro-text test" v-html="$t('pc_intro_text')"></p>

            <form class="ui-pc__form" v-on:submit.prevent="post(form, eligibilityForm)">
                <!-- Name input -->
                <text-input class="ui-pc__form-input" :placeholder="$t('pc_name_placeholder')" name="name" v-model="$v.form.name.$model">
                    <template slot="label"><b>{{ $t("pc_name") }}</b></template>
                    <template v-if="$v.form.name.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_name_error") }}
                    </template>
                </text-input>

                <!-- Telephone Number input  -->
                <text-input class="ui-pc__form-input" inputType="tel" :placeholder="$t('pc_tel_placeholder')" name="phone" v-model="$v.form.sms.$model">
                    <template slot="label"><b>{{ $t("pc_tel") }}</b></template>
                    <template v-if="$v.form.sms.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_tel_error") }}
                    </template>
                </text-input>

                <!-- Email Address input -->
                <text-input class="ui-pc__form-input" inputType="email" :placeholder="$t('pc_email_placeholder')" name="email" v-model="$v.form.email.$model">
                    <template slot="label">
                        <b v-html="$t('pc_email')"></b>
                    </template>
                    <template v-if="$v.form.email.$error" slot="error-msg">
                        <i class="input-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_email_error") }}
                    </template>
                </text-input>

                <study-center-map class="mini-map" id="js-center"></study-center-map>

                <!-- Country Dropdown  -->
                <select-dropdown class="ui-pc__form-input" v-bind:value="countrySelectVal" @change="onCountrySelect($event)" :options="countries" labelFor="locations" ariaLabel="Select location">
                    <template slot="label"><b>{{ $t("pc_site") }}</b></template>
                    <template slot="select-text">{{ $t("pc_location") }}</template>
                    <template v-if="$v.form.country.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>

                <!-- State/City Dropdown  
                <select-dropdown class="ui-pc__form-input" v-bind:value="stateSelectVal" @change="onStateSelect($event)" :options="states" labelFor="states" ariaLabel="Select State">
                    <template slot="select-text"><b>{{ $t("pc_city") }}</b></template>
                    <template v-if="$v.form.state.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>
                -->

                <!-- Site Dropdown  -->
                <select-dropdown class="ui-pc__form-input" v-bind:value="form.site" @change="onSiteSelect($event)" :options="sites" labelFor="sites" ariaLabel="Select Site">
                    <template slot="select-text"><b>{{ $t("pc_site") }}</b></template>
                    <template v-if="$v.form.site.$error" slot="error-msg"> <i class="dropdown-error-icon fas fa-exclamation-circle"></i>{{ $t("pc_dropdown_error") }}</template>
                </select-dropdown>

                <div class="dropdown ui-pc__form-input">
                    <!-- @slot Use this slot to provide label for dropdown -->
                    <label class="dropdown__label" for="locations">
                        <b>{{ $t('pc_preferred_contact_time') }}</b>
                    </label>
                    <select id="locations" aria-label="Select location" class="dropdown__select" @change="onPreferredContactTimeSelect($event)">
                        <option value="">{{ $t('pc_preferred_contact_time') }}</option>
                        <option value="morning">{{ $t('pc_morning') }}</option>
                        <option value="afternoon">{{ $t('pc_afternoon') }}</option>
                        <option value="evening">{{ $t('pc_evening') }}</option>
                    </select>
                </div>

                <!-- Consent Box  -->
                <!-- Consent Box  -->
                <consent-box v-bind:value="form.consent" @change="onConsentChange($event)">
                    <span v-html='$t("pc_consent")' />
                    <template v-if="$v.form.consent.$error" slot="error-msg">
                        <i class="consent-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("pc_consent_error") }}
                    </template>
                </consent-box>

                <centered-layout class="submit-btn-layout">
                    <ui-button :isDisabled="$v.form.$invalid" class="btn--primary pc-submit-btn">
                        {{ $t("pc_submit") }}
                    </ui-button>
                </centered-layout>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import UiButton from "@/components/UiButton.vue";
import TextInputVue from "@/components/TextInput.vue";
import ConsentBoxVue from "../../components/ConsentBox.vue";
import CenteredLayoutVue from "../../components/layout/CenteredLayout.vue";
import SelectDropdownVue from "@/components/SelectDropdown.vue";
import StudyCenterVue from './StudyCenter.vue'
import axios from "axios";
import {
    patientConnectUrl,
    patientConnectHeaders,
    //eligibilityForm,
    sitesTwo
} from "@/config";
import {
    required,
    email,
    sameAs
} from 'vuelidate/lib/validators'
import {
    phoneNumber
} from '@/common/validators'

export default {
    name: "Home",
    components: {
        "ui-button": UiButton,
        "text-input": TextInputVue,
        "select-dropdown": SelectDropdownVue,
        "consent-box": ConsentBoxVue,
        "centered-layout": CenteredLayoutVue,
        "study-center-map": StudyCenterVue,
    },
    props: {
        $eligibilityForm: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            form: {

                // required data to be sent to Longboat
                name: '',
                sms: '',
                email: '',
                site: null,

                // the data below don't need to be sent but they are anyways
                country: null,
                state: null,
                siteId: null,
                preferredContactTime: null,
                consent: null,
            },
            mapKey: "hgjjf",
            countrySelectVal: null,
            stateSelectVal: null,
            selectedCountryId: null,
            selectedStateId: null,

            countries: sitesTwo,
            states: [],
            sites: [],
            eligibilityForm: this.$eligibilityForm
        }
    },
    validations: {
        form: {
            name: {
                required,
            },
            sms: {
                required,
                phoneNumber
            },
            email: {
                email,
            },

            country: {
                required,
            },
            /*
            state: {
                required,
            },
            */
            site: {
                required,
            },

            preferredContactTime: {
                required,
            },

            consent: {
                sameAs: sameAs(() => true),
            }

        }
    },
    methods: {
        onCountrySelect($event) {
            this.countrySelectVal = $event.target.value;
            this.form.country = $event.target.options[$event.target.selectedIndex].text;

            this.$v.form.country.$touch()

            this.selectedCountryId = $event.target.selectedIndex

            this.sites = []; // reinitialize the 'sites' list

            if (this.selectedCountryId != 0) {
                this.sites = this.countries[this.selectedCountryId - 1].states;
            } else {
                // if '-- select --' option was selected
                this.form.country = ''
            }

            //reinitialize data
            // this.form.state = ''
            this.form.site = ''
            this.form.siteId = null
        },
        /*
        onStateSelect($event) {
            this.stateSelectVal = $event.target.value;
            this.form.state = $event.target.options[$event.target.selectedIndex].text;

            this.$v.form.state.$touch()

            this.selectedStateId = $event.target.selectedIndex

            this.sites = []; // reinitialize the 'sites' list if the state id has changed
            if (this.selectedCountryId != 0 && this.selectedStateId != 0) {
                this.sites = this.countries[this.selectedCountryId - 1].states[this.selectedStateId - 1].sites;
            } else {
                // if '-- select --' option was selected
                this.form.state = ''
            }
        },
        */
        onSiteSelect($event) {
            this.form.site = $event.target.value;
            this.form.siteId = $event.target.value

            this.$v.form.site.$touch()
        },
        onPreferredContactTimeSelect($event) {
            this.form.preferredContactTime = $event.target.value;
            this.$v.form.preferredContactTime.$touch()
        },
        onConsentChange($event) {
            this.form.consent = $event;
            this.$v.form.consent.$touch()
        },
        getQueAndAnswerField: function(eligibilityForm) {
            var preferredContactTimeQueAndAnswer = {
                question_id: "preferred_contact_time_id",
                question_text: this.$t("pc_preferred_contact_time"),
                question_answer: this.form.preferredContactTime
            }
    
            /*
            var MedIndicationQueAndAnswer = {
                question_id: "medIndication_id",
                question_text: this.$t("pc_medIndication"),
                question_answer: !this.form.medIndication ? 'N/A' : this.form.medIndication 
            }
            */
            var queAndAnswer = [preferredContactTimeQueAndAnswer, /*MedIndicationQueAndAnswer*/]

            eligibilityForm.forEach((formFields, index) => {
                queAndAnswer.push({
                    question_id: index + 1,
                    question_text: formFields.field_name,
                    question_answer: formFields.field_data
                });
            })
            return queAndAnswer
        },
        post(form, eligibilityForm) {

            this.$v.$touch();
            const isFormInvalid = this.$v.$invalid

            if (isFormInvalid) {
                console.log("form is invalid")
                return
            }

            console.log("form is valid")

            let formData = {
                "language": this.$i18n.locale,
                'name': this.form.name,
                'sms': this.form.sms,
                'email': this.form.email,
                'site': this.form.site,
                'questions': this.getQueAndAnswerField(eligibilityForm),
            };

            console.log("this is the content of form: ", JSON.stringify(formData));

            axios.post(this.getPatientConnectUrl(), formData, {
                    headers: patientConnectHeaders
                })
                .then((res) => {
                    console.log("this is the response: ", res);
                    this.$emit("formSubmittedEvent");
                })
                .catch((error) => {
                    console.log("error just occured: ", error);
                    this.$emit("formSubmissionErrorEvent");
                })
                .finally(() => {
                    //Perform action in always
                });

        },

        getPatientConnectUrl() {
            const isPatientConnectLocal = process.env.VUE_APP_IS_PATIENT_CONNECT_LOCAL;
            if (isPatientConnectLocal === "false") {
                return patientConnectUrl.absolutePath
            } else {
                return patientConnectUrl.rel
            }
        },

        getRestructuredForm: function (eligibilityForm) {

            let newEligibilityForm = []
            eligibilityForm.forEach((formFields, index) => {
                newEligibilityForm.push({
                    ["question_" + (index + 1)]: formFields.field_name,
                    "answer": formFields.field_data,
                })
            })
            return newEligibilityForm;
        },

    },
};
</script>

<style lang="scss">
.ui-pc {
    width: 100%;
    max-width: 900px;

    &__header {
        position: relative;
        background: #4e5157;
        padding: 40px;
        color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &__status {
        width: 80px;
        height: 80px;
        background-color: $primary-color;
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    &__title {
        font-size: 30px;
        margin: 0;
        margin-top: 24px;
        margin-bottom: 12px;
        text-align: center;
        color: $primary-color;
    }

    &__content {
        background-color: #fefefe;
        max-width: 700px;
        margin: 0 auto;
        width: 100%;
        border-radius: 8px;

        @media only screen and (min-width: 676px) {
            margin: 15% auto;
        }
    }

    &__close-btn {
        position: absolute;
        top: 0px;
        right: 0;
        color: white;
        font-size: 28px;
        font-weight: bold;
        padding: 8px 16px;

        &:hover,
        &focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    &__body {
        padding: 32px 16px;
        text-align: center;

        height: 70vh;
        overflow-y: auto;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        .privacy-link {
            color: $accent-color !important;
        }
    }

    &__intro-text {
        padding-bottom: 32px;
        margin-top: 0;
        max-width: 600px;
        margin: auto;
    }

    &__form {
        max-width: 450px;
        margin: 0 auto;
        text-align: left;

        &-input {
            margin-bottom: 16px;
        }
    }
}

.select-dropdown {
    margin-bottom: 16px;
}

.submit-btn-layout {
    padding: 16px 0;
}

.ui-input {
    &__label {
        color: $primary-color;
    }
}

.dropdown {
    &__label {
        color: $primary-color;
        margin-bottom: 0;
    }
}

.ui-custom-checkbox input:checked~.checkmark {
    background-color: $pc-consent-box-color;
    border: 2px solid $pc-consent-box-color;
    top: 4px;
    height: 12px;
    width: 12px;

}

.pc-submit-btn {
    &:disabled {
        background-color: #a8b1bd !important;

        &:hover {
            background-color: #a8b1bd !important;
        }
    }

    background-color: $pc-submit-btn-color !important;

    &:hover {
        background-color: darken($pc-submit-btn-color, 10%) !important;
    }
}
.mini-map{
.map-wrapper {
    display: block;
    margin-bottom: 12px !important;
    position: relative;
    .map-details-wrapper {

        padding: 0 !important;
        width: 100%;

        .map-details {
            max-width: 500px !important;
            width:100% !important;
            width: 100% !important;
            h3{
                font-size: 20px !important;
                text-align: center !important;
                margin-bottom: 8px !important;
            }
            .ui-subhead{
                margin-top: 0 !important;
                margin-bottom: 8px !important;
                font-size: 17px !important;
                text-align: center !important;
            }
        }

    }

    #map {
        height: 300px;
        @media only screen and (min-width: 1025px) {
            width: 100% !important;
        }
    }

    .gm-style-iw.gm-style-iw-c,
    .gm-style-iw-d {
        padding: 0 !important;
        overflow: hidden !important;
        border-radius: 2px;
    }

    div#content {
        width: 290px;
    }

    div#content #bodyContent {
        padding: 12px 16px;
    }

    div#content #bodyContent p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2.gm-heading {
        background-color: $study-center-location-head-color;
        margin: 0;
        font-size: 16px;
        padding: 8px 24px 8px 16px;
        color: #fff;
        letter-spacing: 0.5px;
    }

    .gm-ui-hover-effect img {
        width: 25px !important;
        height: 25px !important;
        background-color: transparent;
    }

    button.gm-ui-hover-effect {
        right: 6px !important;
        top: -3px !important;

        &:focus {
            box-shadow: none;
        }
    }
}

.ui-sdc {
    border: 2px solid #dfdfdf;
    display: flex;
    padding: 4px;
    border-radius: 4px;
    justify-content: space-between;
    width: 100%;
    margin-bottom:8px;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .input-wrapper {
        flex: 1;

        @media only screen and (max-width: 767px) {}
    }

    &__input {
        input {
            margin: 0;
            border: none;
            box-shadow: none;
            font-size: 16px;
            background: none;

            @media only screen and (max-width: 1023px) {
                padding: 12px 2px;
            }

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }
}

.find-center-btn {

    background-color: $study-center-btn-color !important;

    &:hover {
        background-color: darken($study-center-btn-color, 10%) !important;
    }
}

.google-map {
    &.gmap-bg {
        background-image: url("../../assets/images/map-placeholder.png");
        background-repeat: no-repeat;
        background-position: 90%;
        background-size: cover;
    }
}

.map-overlay {
    position: absolute;
    height: 500px;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);

    display: flex;
    align-items: center;

    &.ui-hide {
        visibility: hidden;
        opacity: 0;
        transition: all 0.09s;
    }
}

.map-overlay-wrapper {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    margin: auto;

    &__text {
        color: black;
        text-align: center;
    }
}
}
</style>
