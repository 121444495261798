<template>
<div>
    <site-menu @localeHasChangedEvent="reloadSections"></site-menu>
    <site-banner id="js-home"></site-banner>
    <about-study id="js-about"></about-study>
    <study-info></study-info>
    <timeline></timeline>
    <eligibility-section id="js-eligible" :key="eligSecKey" @gMapInFormConsentEnabledEvent="reloadMap"></eligibility-section>
    <study-center-map id="js-center" :key="mapKey"></study-center-map>
    <faq-section id="js-faqs"></faq-section>
    <share-study id=""></share-study>
    <ui-footer/>
    <copyright-label class="copyright" :locale="$i18n.locale"></copyright-label>
</div>
</template>

<script>
import UiMenuVue from './partials/SiteMenu.vue'
import EligibilityVue from './partials/Eligibility.vue'
import FaqSectionVue from './partials/FaqSection.vue'
import StudyInfoVue from './partials/StudyInfo.vue'
import Timeline from './partials/Timeline.vue'
import StudyCenterVue from './partials/StudyCenter.vue'
import SiteBannerVue from './partials/SiteBanner2.vue'
import AboutStudyVue from './partials/AboutStudy.vue'
import ShareStudyVue from './partials/ShareStudy.vue'
import CopyRightLabelVue from '../components/CopyRightLabel.vue'
import FooterVue from './partials/Footer.vue'

export default {

    components: {
        "eligibility-section": EligibilityVue,
        "study-center-map": StudyCenterVue,
        "site-menu": UiMenuVue,
        "site-banner": SiteBannerVue,
        "faq-section": FaqSectionVue,
        "study-info": StudyInfoVue,
        "timeline": Timeline,
        "about-study": AboutStudyVue,
        "share-study": ShareStudyVue,
        "copyright-label": CopyRightLabelVue,
        "ui-footer" : FooterVue
    },
    data() {
        return {
            mapKey: "hgjjf",
            eligSecKey: "bklof"
        }
    },
    mounted() {
        document.title = this.$t('site_title');
    },

    methods: {
        reloadMap(){
            console
               this.mapKey = this.mapKey + new Date().getSeconds() ;
        },

        reloadEligibilitySection(){
               this.eligSecKey = this.eligSecKey + new Date().getSeconds() ;
        },
        reloadSections(){
            this.reloadMap();
            this.reloadEligibilitySection()
        }
    },

}
</script>

<style lang="scss" scoped>
.copyright {
    padding: 16px;
    background: #4E5157;
    color: white;
    text-align: center;
}
</style>
