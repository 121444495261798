<template>
<section class="eligibility-section ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head>
                <h3 v-html="$t('elig_head')"></h3>
            </template>
            <template #subhead>{{$t('elig_head_p')}}</template>
        </ui-head>

        <question-tab v-for="(item, fIndex) in eligibilityForm" :key="'que' + fIndex" :isActive="item.isActive" class="ui-question-tab" :class="{ 'last-tab': eligibilityForm.length == fIndex + 1 }">
            <template slot="text"> {{ item.question }}</template>
            <template slot="options">
                <div class="select-options">
                    <select-btn v-for="(choice, index) in item.choices" :key="'sel' + index" class="select-btn" :label='fIndex + choice.value' :groupName='"que" + fIndex' :value='choice.value' :englishValue="choice.englishValue" @choiceChange="processChoice(fIndex,$event)"> {{ choice.text }} </select-btn>
                </div>
            </template>
        </question-tab>

        <centered-layout>
            <ui-button :isDisabled="!isEligButtonEnabled" class="elig-check-btn btn--secondary" @click="processEligibility(eligibilityForm)">
                {{$t('check_elig')}}
            </ui-button>
        </centered-layout>

        <modal-layout v-if="isInEligibilityAlertVisible">
            <alert-layout @closeAlertEvent="isInEligibilityAlertVisible = false;" :alertIcon="require(`@/assets/images/ineligble-icon.png`)">
                <template #title>{{ $t('not_eligible') }}</template>
                <template #body>{{ $t('not_eligible_desc') }}</template>
            </alert-layout>
        </modal-layout>

        <!-- PATIENT CONNECT form -->
        <modal-layout v-if="isPatientConnectFormVisible">
            <patient-connect :$eligibilityForm="eligibilityForm" @closePCFormEvent="isPatientConnectFormVisible = false; $emit('gMapInFormConsentEnabledEvent')" @formSubmittedEvent='onFormSubmissionSuccess' @formSubmissionErrorEvent='showFormSubmissionErrorAlert' @gMapInFormConsentEnabledEvent="reemitGMapInFormConsentEnabledEvent"></patient-connect>
        </modal-layout>

        <!-- Display this modal if PC form submission is successful -->
        <modal-layout v-if="isFormSubmissionSuccessAlertVisible">
            <alert-layout @closeAlertEvent="isFormSubmissionSuccessAlertVisible = false; $emit('gMapInFormConsentEnabledEvent')" :alertIcon="require(`@/assets/images/eligble-icon.png`)">
                <template #title>{{ $t('pc_success_h') }}</template>
                <template #body>{{ $t('pc_success') }}</template>
            </alert-layout>
        </modal-layout>

        <!-- Display this modal if PC form submission is unsuccessful -->
        <modal-layout v-if="isFormSubmissionErrorAlertVisible">
            <alert-layout @closeAlertEvent='isFormSubmissionErrorAlertVisible = false' :alertIcon="require(`@/assets/images/ineligble-icon.png`)">
                <template #title>{{ $t('pc_errorh1') }}</template>
                <template #body>
                    <p class="mb-1" v-html="$t('pc_errorp')"></p>
                    <p v-html="$t('pc_errorp1')"></p>
                </template>
            </alert-layout>
        </modal-layout>

    </div>
</section>
</template>

<script>
import UiButton from '@/components/UiButton.vue'

import ModalLayout from '@/components/layout/ModalLayout.vue'
import QuestionTab from '@/components/QuestionTab'
import SelectButtonVue from '@/components/SelectButton.vue'
import CenteredLayoutVue from '../../components/layout/CenteredLayout.vue'
import PatientConnectVue from './PatientConnectTwo.vue'
import AlertLayoutVue from '../../components/layout/AlertLayout.vue'
import UiHeadingVue from '../../components/UiHeading.vue'
import axios from "axios";
import {
    eligibilityForm,
    patientConnectUrl,
    patientConnectHeaders,
} from '@/config'

export default {
    name: 'Home',
    components: {
        "ui-button": UiButton,
        "modal-layout": ModalLayout,
        "question-tab": QuestionTab,
        "select-btn": SelectButtonVue,
        "centered-layout": CenteredLayoutVue,
        "patient-connect": PatientConnectVue,
        "alert-layout": AlertLayoutVue,
        "ui-head": UiHeadingVue

    },
    created() {
        this.addIsActiveProperty(this.eligibilityForm)
    },
    data() {
        return {
            isPatientConnectFormVisible: false,
            isInEligibilityAlertVisible: false,
            isFormSubmissionErrorAlertVisible: false,
            isFormSubmissionSuccessAlertVisible: false,
            isEligButtonEnabled: false,
            eligibilityForm: eligibilityForm(this)
        }
    },
    methods: {
        addIsActiveProperty(eligibilityForm) {
            for (let i = 0; i < eligibilityForm.length; i++) {
                this.$set(eligibilityForm[i], 'isActive', false);
            }
        },
        onFormSubmissionSuccess: function () {
            this.isPatientConnectFormVisible = false;
            this.isFormSubmissionSuccessAlertVisible = true;
        },

        showFormSubmissionErrorAlert: function () {
            this.isFormSubmissionErrorAlertVisible = true;
        },

        processChoice: function (queLabel, {
            value,
            englishValue
        }) {
            this.eligibilityForm[queLabel].userAnswer = value; // this adds a new 'userAnswer' property to the 'eligibilityForm'
            // console.log("answer is: ", value)

            // set the field_data
            this.eligibilityForm[queLabel].field_data = englishValue;
            // console.log("answer is: ", englishValue)

            this.eligibilityForm[queLabel].isActive = true;
            this.checkIsEligButtonEnabled(this.eligibilityForm);
        },

        checkIsEligButtonEnabled: function (eligibilityForm) {
            if (eligibilityForm !== null) {
                let isAllQuestionAnswered = true;

                for (const element of eligibilityForm) {
                    isAllQuestionAnswered = isAllQuestionAnswered && element.isActive;
                    //console.log("run-down of activeness: que" + element + " " , element.isActive)
                }
                if (isAllQuestionAnswered) {
                    this.isEligButtonEnabled = true;
                } else {
                    this.isEligButtonEnabled = false;
                }
            }

        },

        isValid: function (input) {
            let isUserAnswerValid = false
            input.correctAnswers.forEach((value) => {
                if (value == input.userAnswer)
                    isUserAnswerValid = true
            })
            return isUserAnswerValid;
        },

        isAllValid: function (eligibilityForm) {
            let isUserAnswersAllCorrect = true
            eligibilityForm.forEach((x) => {
                let _isValid = this.isValid(x)
                isUserAnswersAllCorrect = isUserAnswersAllCorrect && _isValid
            })
            return isUserAnswersAllCorrect
        },

        processEligibility: function (eligibilityForm) {

            if (this.isAllValid(eligibilityForm)) {
                console.log("you are eligible")
                this.isPatientConnectFormVisible = true;
            } else {
                console.log("you are not eligible")
                this.post(eligibilityForm)
                this.isInEligibilityAlertVisible = true;
            }

        },
        getPatientConnectUrl() {
            const isPatientConnectLocal = process.env.VUE_APP_IS_PATIENT_CONNECT_LOCAL;
            if (isPatientConnectLocal === "false") {
                return patientConnectUrl.absolutePath
            } else {
                return patientConnectUrl.rel
            }
        },
        getRestructuredForm: function (eligibilityForm) {

            let newEligibilityForm = []
            eligibilityForm.forEach((formFields, index) => {
                newEligibilityForm.push({
                    ["question_" + (index + 1)]: formFields.field_name,
                    "answer": formFields.field_data,
                })
            })
            return newEligibilityForm;
        },
        getRestructuredForm2: function (formObject, eligibilityForm) {
            var Quekeys= ['first_question', 'second_question', 'third_question', 'fourth_question', 'fifth_question', 'sixth_question', 'seventh_question', 'eighth_question', 'nighth_question']
            function copyFormData(formData) {
                const copiedFormData = {
                    language: formData.language,
                   // fields: formData.fields,
                    name: formData.name,
                    sms: formData.sms,
                    email: formData.email,
                    site: formData.site,
                };
                return copiedFormData;
            }

            let newEligibilityForm = copyFormData(formObject);
            eligibilityForm.forEach((formFields, index) => {
                newEligibilityForm[Quekeys[index]] = 'q' + (index + 1) + ':' + formFields.field_name + 'a' + (index + 1) + ': ' + formFields.field_data
            })
            return newEligibilityForm;
        },

        post: function (eligibilityForm) {
            let formData = {
                "language": this.$i18n.locale,
                "fields": this.getRestructuredForm(eligibilityForm)
            }

            let restructuredForm = this.getRestructuredForm2(formData, eligibilityForm)

            console.log("this is the content of eligibilityForm: ", JSON.stringify(restructuredForm));

            axios.post(this.getPatientConnectUrl(), restructuredForm, {
                    headers: patientConnectHeaders
                })
                .then((res) => {
                    console.log("this is the response: ", res);
                })
                .catch((error) => {
                    console.log("error just occured: ", error);
                })
                .finally(() => {
                    //Perform action in always
                });

        },

        reemitGMapInFormConsentEnabledEvent() {
            this.$emit('gMapInFormConsentEnabledEvent')
        }
    },
}
</script>

<style lang="scss">
.ui-question-tab {
    margin-bottom: 16px;
}

.last-tab:before {
    content: none;
}

.select-options {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.select-btn {
    margin-top: 16px;
    margin-right: 16px;
}

.eligibility-section {
    background: $elig-section-bg-color;
}

.question-tab {

    &--active {

        &:before,
        .question-tab__indicator {
            background-color: $elig-indicator-bg-color;
        }
    }

}

.radio__input:checked+.radio__label {
    background-color: $elig-select-btn-color-selected;
}

.radio__input:focus+.radio__label {
    border: 2px dashed $elig-select-btn-color-onfocus;
}

.radio__label:hover {
    background-color: $elig-select-btn-color-onhover;
}

.elig-check-btn {

    &:disabled {
        background-color: #a8b1bd !important;

        &:hover {
            background-color: #a8b1bd !important;
        }
    }

    background-color: $elig-check-btn-color !important;

    &:hover {
        background-color: darken($elig-check-btn-color, 10%) !important;
    }

}
</style>
